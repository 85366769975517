/** @jsx jsx */
import { useTranslate } from '@chordcommerce/gatsby-theme-performance'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import { Box, Heading, jsx } from 'theme-ui'
import LineItem from '~/components/Cart/LineItem'
import formatMoney from '~/utils/formatMoney'

const LineItems = ({
  items = [],
  orderConfirmed = false,
  amountNeededForFreeShipping,
  eligibleForFreeShipping = false
}) => {
  const data = useStaticQuery(graphql`
    query {
      products: allContentfulProduct {
        nodes {
          slug
          collection {
            lineup {
              lineup {
                slug
                # breadcrumbSlug
              }
              slug
              # breadcrumbSlug
            }
            slug
          }
        }
      }
    }
  `)

  const translate = useTranslate()

  items = items.filter(item => item && !item.variant.sample)

  return (
    <Box>
      <Heading
        as="h2"
        variant="h2"
        sx={{
          textTransform: 'uppercase',
          paddingBottom: 2,
          borderBottom: theme => `1px solid ${theme.colors.grey70}`,
          marginBottom: 4,
          fontSize: ['2.4rem !important', '3rem !important'],
          fontWeight: 'medium'
        }}
      >
        {translate('cart.your_items')}
      </Heading>

      {/* {!orderConfirmed && (
        <Flex
          sx={{
            alignItems: 'center',
            height: '3rem',
            justifyContent: 'space-around'
          }}
        >
          {eligibleForFreeShipping
            ? translate('cart.shipping_banner_free')
            : translate('cart.shipping_banner_not_free', {
                amount: toUsdCurrency(amountNeededForFreeShipping)
              })}
        </Flex>
      )} */}

      {items.map((li, index) => {
        const { quantity, discountAllocations, variant } = li
        const { price, selectedOptions } = variant

        const totalDiscounts = +discountAllocations.reduce(
          (acc, da) => acc + +da.allocatedAmount.amount,
          0
        )

        const slug = li.variant && li.variant.product.handle

        const displayAmount = formatMoney(price * quantity - totalDiscounts)
        const optionsDisplay = selectedOptions
          .map(({ name, value }) => `${name}: ${value}`)
          .join(', ')

        const product = data.products?.nodes?.find(prod => prod.slug === slug)

        const collection =
          product?.collection?.find(c => c.lineup !== null) ??
          product?.collections?.find(c => c.lineup !== null)

        const [parentLineup] = collection?.lineup ?? []
        const [rootLineup] = parentLineup?.lineup ?? []

        const productPath =
          parentLineup || rootLineup
            ? `/${
                rootLineup
                  ? rootLineup.breadcrumbSlug || rootLineup.slug
                  : parentLineup.breadcrumbSlug || parentLineup.slug
              }/${collection?.slug}/${product?.slug}`
            : null

        return (
          <Box
            key={li.id}
            sx={{
              paddingBottom: 4,
              borderBottom: theme => `1px solid ${theme.colors.grey70}`,
              ':not(:first-of-type)': {
                paddingTop: 4
              },
              ':last-of-type': {
                borderBottom: theme => [
                  0,
                  null,
                  `1px solid ${theme.colors.grey70}`
                ]
              }
            }}
          >
            <LineItem
              id={li.id}
              variantImage={li.variant.image}
              sku={li.variant && li.variant.sku}
              slug={li.variant && li.variant.product.handle}
              productPath={productPath}
              name={li.title}
              options={optionsDisplay}
              quantity={li.quantity}
              displayAmount={displayAmount}
              singleDisplayAmount={formatMoney(price)}
              orderConfirmed={orderConfirmed}
            />
          </Box>
        )
      })}
    </Box>
  )
}

LineItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      src: PropTypes.string,
      quantity: PropTypes.number,
      displayAmount: PropTypes.string,
      singleDisplayAmount: PropTypes.string
    })
  ),
  orderConfirmed: PropTypes.bool,
  amountNeededForFreeShipping: PropTypes.number,
  eligibleForFreeShipping: PropTypes.bool
}

export default LineItems

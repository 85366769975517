/** @jsx jsx */
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Button, Flex, Input, jsx, Spinner, Text } from 'theme-ui'
import { useCart, useTranslate } from '@chordcommerce/gatsby-theme-performance'
import { Fragment } from 'react'

const PromoCode = ({ promotion }) => {
  const translate = useTranslate()
  const { cart, addPromoCode, removePromoCode } = useCart()

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      promoCode: promotion ? promotion.code : ''
    }
  })

  const { isFetching } = cart.promoCode
  const [apiError, setApiError] = useState(null)

  const onSubmit = async (data, e) => {
    if (promotion) {
      try {
        setApiError(null)
        await removePromoCode(promotion.code)
        e.target.reset()
      } catch (error) {
        setApiError(error)
      }
    } else {
      try {
        setApiError(null)
        await addPromoCode(data.promoCode)
      } catch (error) {
        setApiError(error)
      }
    }
  }

  return (
    <Fragment>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <label sx={{ display: 'block', marginBottom: '0.8rem' }}>
          {translate('cart.promo_code')}
        </label>

        <Flex
          dir="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Input
            sx={{
              variant: 'text.body',
              width: '80%',
              marginRight: '1.6rem',
              paddingLeft: [0, null, '1rem'],
              border: theme => `1px solid ${theme.colors.border}`,
              backgroundColor: '#E2E2E2'
            }}
            name="promoCode"
            required={true}
            ref={register({ required: true })}
          />

          <Button type="submit" sx={{ '&:focus': { outline: 'none' } }}>
            {isFetching && (
              <Spinner aria-label="Loading..." size="15" color="inherit" />
            )}

            {!isFetching && (
              <Fragment>
                {promotion
                  ? translate('cart.remove_promo_code_button')
                  : translate('cart.apply_promo_code_button')}
              </Fragment>
            )}
          </Button>
        </Flex>
      </Box>

      {apiError && (
        <Text
          variant="formError"
          sx={{
            color: 'errorDark',
            backgroundColor: 'errorLight',
            px: '16px',
            py: '8px'
          }}
        >
          Invalid Promo Code
        </Text>
      )}

      {errors.promoCode && (
        <Text
          variant="formError"
          sx={{
            color: 'errorDark',
            backgroundColor: 'errorLight',
            px: '16px',
            py: '8px'
          }}
        >
          {translate('validation.required_field')}
        </Text>
      )}
    </Fragment>
  )
}

PromoCode.propTypes = {
  promotion: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    amount: PropTypes.number,
    displayAmount: PropTypes.string
  })
}

export default PromoCode

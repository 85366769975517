/** @jsx jsx */
import { useState } from 'react'
import {
  logSentryError,
  useCart,
  useTranslate
} from '@chordcommerce/gatsby-theme-performance'
import { Button, Text, Spinner, jsx } from 'theme-ui'

const CheckoutButton = () => {
  const { checkout } = useCart()
  const translate = useTranslate()
  const [buttonIsLoading, setButtonIsLoading] = useState(false)
  const [apiError, setApiError] = useState(null)

  const handleSubmit = async event => {
    if (event) event.preventDefault()

    setButtonIsLoading(true)
    setApiError(null)

    try {
      await checkout()
    } catch (error) {
      setApiError(translate('error.api.default'))
      logSentryError(error, { source: 'CheckoutButton' })
    }

    setButtonIsLoading(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Button
        sx={{
          marginTop: '1rem',
          width: '100%',
          lineHeight: ['49px', '54px'],
          height: ['49px !important', '54px !important'],
          backgroundColor: 'black',
          color: 'white !important',
          fontSize: [3, 4],
          '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.9)'
          }
        }}
      >
        {buttonIsLoading && (
          <Spinner
            aria-label="Loading..."
            data-testid="spinner"
            size="15"
            color="inherit"
          />
        )}

        {!buttonIsLoading && <Text>{translate('cart.checkout')}</Text>}
      </Button>

      {apiError && (
        <Text color="errorDark" variant="textLink" mt="1.5rem">
          {apiError}
        </Text>
      )}
    </form>
  )
}

export default CheckoutButton

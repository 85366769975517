/** @jsx jsx */
import { useAnalytics } from '@chordcommerce/gatsby-theme-performance'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Box, Flex, Heading, jsx, Text, Image } from 'theme-ui'
import QuantitySelector from '~/components/Cart/QuantitySelector'

const LineItem = ({
  id,
  sku,
  slug,
  variantImage,
  productPath,
  name,
  options,
  quantity,
  displayAmount,
  orderConfirmed = false
}) => {
  const { trackProductClicked } = useAnalytics()
  const path = sku.includes('|') ? 'bundles' : 'products'
  const url = `/${path}/${slug}/`
  return (
    <Flex
      sx={{
        flexDirection: ['column', null, 'row'],
        justifyContent: 'space-between'
      }}
    >
      <Flex
        sx={{
          flexDirection: ['row']
        }}
      >
        <Box
          sx={{
            width: ['12rem', null, '14rem'],
            height: ['12rem', null, '14rem'],
            marginRight: 3,
            a: {
              display: 'block',
              width: '100%',
              height: '100%'
            }
          }}
        >
          <Link
            to={productPath ? productPath : url}
            onClick={() => trackProductClicked({ productHandle: slug })}
            sx={{
              img: { width: '100%', height: '100%', objectFit: 'contain' }
            }}
          >
            {variantImage && variantImage.originalSrc && (
              <Image src={variantImage.originalSrc} alt={''} />
            )}
          </Link>
        </Box>

        <Box>
          <Heading
            as="h3"
            sx={{
              marginBottom: '0.8rem',
              variant: 'text.h3'
            }}
          >
            {name}
          </Heading>

          {!options.includes('Default Title') && (
            <Text
              sx={{
                marginBottom: '0.8rem',
                variant: 'text.h4',
                fontSize: [2, 3]
              }}
            >
              {options}
            </Text>
          )}

          {!orderConfirmed && (
            <Flex dir="row" sx={{ alignItems: 'center' }}>
              <Text mr={2} variant="h4" sx={{ fontSize: [2, 3] }}>
                Quantity:
              </Text>

              <QuantitySelector
                quantity={quantity}
                lineItemId={id}
                sku={sku}
                sx={{
                  fontSize: 3,
                  height: ['3.6rem', null, '3.6rem'],
                  maxWidth: '8rem',
                  padding: '0 0.8rem'
                }}
              />
            </Flex>
          )}
        </Box>
      </Flex>

      <Flex
        sx={{
          flexDirection: ['row', null, 'column'],
          alignItems: 'flex-end',
          justifyContent: ['flex-end', null, 'flex-start'],
          marginTop: [2, null, 0]
        }}
      >
        {/* {orderConfirmed && <Text>{quantity}</Text>} */}

        <Text variant="body">{displayAmount}</Text>
      </Flex>
    </Flex>
  )
}

LineItem.propTypes = {
  id: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string,
  quantity: PropTypes.number,
  displayAmount: PropTypes.string,
  orderConfirmed: PropTypes.bool
}

export default LineItem

/** @jsx jsx */

import { useEffect, useState } from 'react'
import { Box, Container, Flex, Heading, jsx, Spinner } from 'theme-ui'
import {
  useCart,
  useTranslate,
  useUser
} from '@chordcommerce/gatsby-theme-performance'
import OrderConfirmationReferralPrompt from '~/components/Order/Confirmation/ReferralPrompt'
import OrderConfirmationSummary from '~/components/Order/Confirmation/Summary'
import OrderConfirmationCart from '~/components/Order/Confirmation/Cart'
import OrderConfirmationDetails from '~/components/Order/Confirmation/Details'
import OrderConfirmationContact from '~/components/Order/Confirmation/Contact'

const OrderConfirmationPage = () => {
  const { user, loadUser } = useUser()
  const { finalizeCheckout } = useCart()
  const [cart, setCart] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    finalizeCheckout()
      .then(cart => {
        setCart(cart)
        return loadUser(cart.email)
      })
      .then(() => {
        setIsLoading(false)
      })
      .catch(error => {
        setError(error)
      })
  }, [finalizeCheckout, loadUser])

  const referralPurl = user.referralIdentifier && user.referralIdentifier.purl

  if (error) return <ConfirmationError error={error} />
  if (isLoading || !cart) return <ConfirmationLoading />

  return (
    <ConfirmationContainer cart={cart}>
      <Container>
        <Flex
          sx={{
            flexDirection: ['column', null, 'row']
          }}
        >
          <Flex
            sx={{
              width: ['100%', null, '60%'],
              flexDirection: 'column',
              paddingRight: [null, null, '0.75rem'],
              textAlign: ['center', null, 'left']
            }}
          >
            <OrderConfirmationDetails cart={cart} />

            {referralPurl && (
              <OrderConfirmationReferralPrompt purl={referralPurl} />
            )}

            <OrderConfirmationContact />
          </Flex>

          <Flex
            sx={{
              width: ['100%', null, '40%'],
              flexDirection: 'column',
              paddingLeft: [null, null, '0.75rem']
            }}
          >
            <OrderConfirmationSummary cart={cart} />
            <OrderConfirmationCart cart={cart} />
          </Flex>
        </Flex>
      </Container>
    </ConfirmationContainer>
  )
}

const ConfirmationLoading = () => {
  return (
    <ConfirmationContainer loaded={false}>
      <Container>
        <Box
          sx={{
            textAlign: 'center',
            width: '100%',
            backgroundColor: 'white',
            padding: ['1.25rem', '12rem 1.25rem'],
            marginRight: [null, '1rem'],
            marginBottom: ['1rem', null]
          }}
        >
          <Spinner aria-label="Loading..." size="50" />
        </Box>
      </Container>
    </ConfirmationContainer>
  )
}

const ConfirmationError = () => {
  const translate = useTranslate()

  return (
    <ConfirmationContainer loaded={false}>
      <Container>
        <Box
          sx={{
            width: '100%',
            backgroundColor: 'white',
            padding: '1rem',
            minHeight: '50vh'
          }}
        >
          <Heading variant="h3" color="errorDark">
            {translate('error.api.default')}
          </Heading>
        </Box>
      </Container>
    </ConfirmationContainer>
  )
}

const ConfirmationContainer = ({ children, cart = {}, loaded = true }) => {
  const translate = useTranslate()
  const firstName = cart.billAddress && cart.billAddress.firstname

  return (
    <Container>
      <Flex
        sx={{
          flexDirection: 'column',
          padding: ['24px 0', null, '48px 0']
        }}
      >
        {loaded && (
          <Heading
            as="h2"
            variant="h2"
            sx={{
              marginBottom: ['24px', null, '64px'],
              textAlign: 'center'
            }}
          >
            {`${translate('confirmation.title')}, ${firstName}.`}
          </Heading>
        )}
        {children}
      </Flex>
    </Container>
  )
}

export default OrderConfirmationPage

import React from 'react'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import OrderConfirmationPage from '~/components/Order/Confirmation/Page'
import { useTranslate } from '@chordcommerce/gatsby-theme-performance'

const OrderConfirmation = () => {
  const translate = useTranslate()

  return (
    <Layout>
      <Metadata title={translate('confirmation.page_title')} />
      <OrderConfirmationPage />
    </Layout>
  )
}

export default OrderConfirmation

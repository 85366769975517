import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import QuantitySelector from '~/components/Generic/QuantitySelector'
import { useCart } from '@chordcommerce/gatsby-theme-performance'
import { graphql, useStaticQuery } from 'gatsby'

const CartQuantitySelector = ({ quantity, lineItemId, sku, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      variants: allContentfulVariant(
        filter: { limitOnePerCustomer: { eq: true } }
      ) {
        edges {
          node {
            sku
          }
        }
      }
    }
  `)
  const limitedVariants = data.variants.edges.map(edge => edge.node)
  const { modifyQuantity, removeFromCart } = useCart()
  const [isLoading, setIsLoading] = useState(false)

  const maxQuantity = limitedVariants.some(variant => variant.sku === sku)
    ? 1
    : Math.max(quantity, 20)
  const options = [{ name: '0 - Remove', value: 0 }].concat(
    [...Array(maxQuantity)].map((_, i) => ({
      name: (i + 1).toString(),
      value: i + 1
    }))
  )

  const updateQuantity = async quantity => {
    setIsLoading(true)
    const intQuantity = parseInt(quantity)
    try {
      if (intQuantity === 0) {
        await removeFromCart(lineItemId)
      } else {
        await modifyQuantity(lineItemId, intQuantity)
      }
    } catch (error) {}
  }

  useEffect(() => setIsLoading(false), [quantity, lineItemId])

  return (
    <QuantitySelector
      defaultValue={quantity}
      isLoading={isLoading}
      handleChange={updateQuantity}
      options={options}
      {...props}
    />
  )
}

CartQuantitySelector.propTypes = {
  lineItemId: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired
}

export default CartQuantitySelector

/** @jsx jsx */
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { Flex, jsx, Select, Spinner } from 'theme-ui'

const QuantitySelector = ({ handleChange, options = [], ...props }) => {
  const { register, getValues } = useForm()

  const onChange = () => {
    const quantity = getValues('quantity')
    if (handleChange) handleChange(quantity)
  }

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        minWidth: '80px'
      }}
    >
      {props.isLoading && <Spinner aria-label="Loading..." color="inherit" />}
      {!props.isLoading && (
        <Select
          sx={{
            fontSize: ['1.3rem', '1.8rem', '1.5rem', null, '2rem'],
            height: ['3.8rem', '4rem', '3.2rem', '4.4rem'],
            padding: ['0 1.4rem', null, '0 0.6rem', '0 1.4rem']
          }}
          name="quantity"
          aria-label="Quantity"
          onChange={onChange}
          ref={register}
          {...props}
        >
          {options.map(({ name, value }) => (
            <option key={value} value={value}>
              {name || value}
            </option>
          ))}
        </Select>
      )}
    </Flex>
  )
}

QuantitySelector.propTypes = {
  handleChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired
    })
  )
}

export default QuantitySelector
